<template>
  <div class="order-item flex">
    <div class="img">
      <img :src="orderItem.goodsSkuPic" alt="" srcset="" />
    </div>
    <div class="span1 m-f-24">
      <div>{{orderItem.goodsName}}</div>
      <div class="m-c-adad">套餐：{{orderItem.goodsSkuDesc || orderItem.goodsSkuName}}</div>
      <div class="m-c-adad" v-if="orderItem.planIndex && !type">租期：{{orderItem.planIndex}}个月</div>
      <div class="m-c-adad" v-if="props.signPrice">签约价：￥{{props.signPrice}}</div>
      <div class="m-c-adad" v-if="props.rentAmount">总租金：￥{{props.rentAmount}}</div>
      <div class="m-c-adad" v-if="orderItem.monthlyPrice">月租金：￥{{orderItem.monthlyPrice}}</div>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from 'vue';

/** props */
const props = defineProps({
  orderItem: String, // 商品详情
  signPrice: {
    type: String || Number, // 签约价
  },
  rentAmount: {
    type: String || Number, // 总租金
  },
  type: String,
});
</script>
<style lang="scss" scoped>
  .order-item{
    padding: 24px 0 0;
    .img {
      width: 160px;
      height: 160px;
      margin-right: 20px;
      > img {
        @include img;
      }
    }
  }
</style>
